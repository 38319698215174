const isProd = !!process.env.NEXT_PUBLIC_IS_PROD;

const appUrl = process.env.NEXT_PUBLIC_APP_URL || 'https://app.localprof.com';

const apiUrl = isProd
  ? process.env.NEXT_PUBLIC_API_URL
  : process.env.NEXT_PUBLIC_API_URL || 'https://devapi.localprof.com';

if (!apiUrl) {
  throw new Error('NEXT_PUBLIC_API_URL is not defined');
}

const config = {
  isProd,
  apiUrl,
  appUrl,
} as const;

export default config;
