import { Icon, IconProps } from '@chakra-ui/react';

const CloseIcon = (props: Omit<IconProps, 'children' | 'viewBox'>) => (
  <Icon
    fill='none'
    height='100%'
    stroke='currentColor'
    strokeLinecap='round'
    strokeLinejoin='round'
    strokeWidth='2'
    viewBox='0 0 24 24'
    width='100%'
    {...props}
  >
    <path
      d='M0 0h24v24H0z'
      fill='none'
      stroke='none'
    />
    <path d='M18 6l-12 12' />
    <path d='M6 6l12 12' />
  </Icon>
);

export default CloseIcon;
