import { ReactNode, createContext, useContext, useEffect, useState } from 'react';
import { throttle } from '@/shared/helpers';

const isWindow = typeof window === 'object';

interface GlobalStateProviderProps {
  children: ReactNode;
  isMobileInitValue: boolean;
}

interface GlobalStateData {
  isMobile: boolean;
}

const GlobalStateContext = createContext<GlobalStateData | null>(null);

export const useGlobalState = () => {
  const data = useContext(GlobalStateContext);

  if (data === null) {
    throw new Error('Can not use GlobalStateContext outside globalStateProvider');
  }

  return data;
};

const GlobalStateProvider = ({ children, isMobileInitValue }: GlobalStateProviderProps) => {
  const [isMobile, setIsMobile] = useState(isMobileInitValue);

  useEffect(() => {
    const handleWindowResize = () => {
      throttle(() => setIsMobile(window.innerWidth < 768))();
    };

    if (isWindow) {
      handleWindowResize();
      window.addEventListener('resize', handleWindowResize);
    }
    return () => {
      if (isWindow) window.removeEventListener('resize', handleWindowResize);
    };
  }, []);

  return <GlobalStateContext.Provider value={{ isMobile }}>{children}</GlobalStateContext.Provider>;
};

export default GlobalStateProvider;
