import { fbLink, instagramLink } from '@/shared/constants';
import { FacebookIcon, InstagramIcon } from '@/shared/ui';

export const socialMediaLinks = [
  {
    href: instagramLink,
    Icon: InstagramIcon,
    ariaLabel: 'Instagram link',
  },
  {
    href: fbLink,
    Icon: FacebookIcon,
    ariaLabel: 'Facebook link',
  },
];
