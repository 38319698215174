import { ChakraProvider } from '@chakra-ui/react';
import type { AppProps } from 'next/app';
import TagManager from 'react-gtm-module';
import { theme } from '@/app/theme';
import { Footer } from '@/widgets/Footer';
import { GQLProvider } from '@/widgets/GQLProvider';
import { Header } from '@/widgets/header';
import { Device } from '@/shared/enums';
import { parseSSGParams } from '@/shared/helpers';
import { GlobalStateProvider } from '@/shared/providers';

const gtmId = process.env.gtmId;

if (gtmId) {
  const tagManagerArgs = {
    gtmId,
  };

  if (typeof window !== 'undefined') {
    setTimeout(() => TagManager.initialize(tagManagerArgs), 3000);
  }
}

export default function MyApp({ Component, pageProps, router }: AppProps) {
  const { device } = parseSSGParams(router.query);
  const isMobile = device === Device.MOBILE;

  return (
    <ChakraProvider theme={theme}>
      <GlobalStateProvider isMobileInitValue={isMobile}>
        <GQLProvider>
          <Header />
          <Component {...pageProps} />
          <Footer />
        </GQLProvider>
      </GlobalStateProvider>
    </ChakraProvider>
  );
}
