import { ParsedUrlQuery } from 'querystring';
import { Device } from '@/shared/enums';

interface DefaultPageParams extends ParsedUrlQuery {
  ssgParams: string;
}

export type PageParams<T extends string | void = void> = T extends string
  ? Record<T, string> & DefaultPageParams
  : DefaultPageParams;

interface SSGParams {
  device: Device;
}

const parseSSGParams = (query?: ParsedUrlQuery | PageParams): SSGParams => {
  const ssgParams = query?.ssgParams as string | undefined;

  if (!ssgParams)
    return {
      device: Device.MOBILE,
    };

  const device = ssgParams;

  return {
    device: device as Device,
  };
};

export default parseSSGParams;
