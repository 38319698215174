const overlayStyles = { backdropFilter: 'blur(3px)' };

export const components = {
  Button: {
    variants: {
      iconButton: {
        bg: 'none',
        color: 'icon.dark',
        size: 'sm',
        minW: 8,
        h: 8,
      },
    },
    defaultProps: {
      colorScheme: 'primary',
    },
  },
  Drawer: {
    baseStyle: {
      overlay: overlayStyles,
    },
  },
  Modal: {
    baseStyle: {
      overlay: overlayStyles,
    },
  },
};
