import { IconProps } from '@chakra-ui/react';
import { FC } from 'react';
import { LandingIcon, LocationsIcon, ReviewIcon } from '@/shared/ui';

export const brand = 'LocalProf';
export const email = 'support@localprof.com';
export const prodUrl = 'https://localprof.com';

export const instagramLink = 'https://www.instagram.com/local_prof';
export const fbLink = 'https://www.facebook.com/localprof.usa';

export const headerHeight = '4rem';

interface INavLinkItem {
  name: string;
  description: string;
  Icon: FC<IconProps>;
  href: string;
}
interface INavLinks {
  title: string;
  href?: string;
  items?: INavLinkItem[];
}

export const navLinks: INavLinks[] = [
  {
    title: 'Products',
    items: [
      {
        name: 'Locals',
        description: 'Manage business profiles on different platforms at one',
        Icon: LocationsIcon,
        href: '/products/locals',
      },
      {
        name: 'Reviews',
        description: 'Learn your reputation and increase your success',
        Icon: ReviewIcon,
        href: '/products/reviews',
      },
      {
        name: 'Pages',
        description: 'Create your web site and config it as you want',
        Icon: LandingIcon,
        href: '/products/pages',
      },
    ],
  },
  { title: 'Pricing', href: '/pricing' },
];

export const privacyPages = [
  { name: 'Terms & Conditions', url: 'terms' },
  { name: 'Cookie Policy', url: 'cookie' },
  { name: 'Privacy Policy', url: 'privacy' },
];
