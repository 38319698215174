import { Link } from '@chakra-ui/next-js';
import { Flex, Text } from '@chakra-ui/react';
import { brand, email, navLinks, privacyPages } from '@/shared/constants';
import { DefaultContainer, LocalprofLogo } from '@/shared/ui';
import SocialMediaLinks from './SocialMediaLinks';

const linkBoxStyles = {
  alignItems: 'center',
  gap: 'sm',
  justifyContent: 'center',
};

const Footer = () => {
  return (
    <DefaultContainer
      alignItems='center'
      bg='bg.dark'
      flexDir={{ base: 'column-reverse', md: 'row' }}
      gap='md'
      justifyContent='space-between'
    >
      <Flex
        alignItems='center'
        flexDir='column'
      >
        <Flex
          _hover={{
            transform: 'scale(1.01)',
          }}
          alignItems='center'
          as={Link}
          gap='sm'
          href='/'
        >
          <LocalprofLogo
            boxSize={8}
            color='icon.light'
          />

          <Text
            color='text.light'
            fontSize='xl'
            fontWeight='semibold'
          >
            {brand}
          </Text>
        </Flex>

        <Flex
          alignItems='center'
          flexDir={{ base: 'row', md: 'column' }}
          mt='sm'
        >
          <Text color='text.light'>2024 {brand} LLC.</Text>
          <Text
            color='text.light'
            ml={{ base: 2, md: 0 }}
          >
            All rights reserved
          </Text>
        </Flex>
      </Flex>

      <Flex
        as='nav'
        flexDir='column'
        gap='sm'
      >
        <Flex
          as='ul'
          {...linkBoxStyles}
          flexWrap='wrap'
        >
          {navLinks.map(({ title, items, href }) => {
            if (items) {
              return (
                <>
                  {items.map(({ name, href }) => (
                    <Link
                      key={name}
                      as='li'
                      color='text.light'
                      href={href}
                    >
                      {name}
                    </Link>
                  ))}
                </>
              );
            }
            return (
              <Link
                key={title}
                as='li'
                color='text.light'
                href={href!}
              >
                {title}
              </Link>
            );
          })}
        </Flex>
        <Flex
          as='ul'
          {...linkBoxStyles}
          flexWrap='wrap'
        >
          {privacyPages.map(({ name, url }) => (
            <Link
              key={name}
              as='li'
              color='text.light'
              href={`/${url}`}
            >
              {name}
            </Link>
          ))}
        </Flex>
      </Flex>

      <Flex
        flexDir='column'
        gap='sm'
      >
        <Link
          color='text.light'
          fontSize='lg'
          fontWeight='semibold'
          href={`mailto:${email}`}
          textAlign='center'
        >
          {email}
        </Link>
        <SocialMediaLinks />
      </Flex>
    </DefaultContainer>
  );
};

export default Footer;
