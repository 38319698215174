import { extendTheme } from '@chakra-ui/react';
import colors from './colors';
import { components } from './components';
import { containerSizes } from './sizes';
import spaces from './spaces';

const theme = extendTheme({
  colors,
  space: {
    ...spaces,
  },
  sizes: {
    container: containerSizes,
  },
  components,
  styles: {
    global: {
      body: {
        color: 'text.pure',
      },
    },
  },
});

export default theme;
