import ArrowDown from './ArrowDown';
import BoltIcon from './BoltIcon';
import BurgerIcon from './BurgerIcon';
import CheckIcon from './CheckIcon';
import CloseIcon from './CloseIcon';
import ConsistentIcon from './ConsistentIcon';
import ControlIcon from './ControlIcon';
import DashboardIcon from './DashboardIcon';
import ExpandIcon from './ExpandIcon';
import FacebookIcon from './FacebookIcon';
import GrowthIcon from './GrowthIcon';
import InstagramIcon from './InstagramIcon';
import LandingIcon from './LandingIcon';
import LocalprofLogo from './LocalprofLogo';
import LocationsIcon from './LocationsIcon';
import PointIcon from './PointIcon';
import RequestIcon from './RequestIcon';
import ReviewIcon from './ReviewIcon';
import SurveysIcon from './SurveysIcon';
import UsersIcon from './UsersIcon';

export {
  ArrowDown,
  BoltIcon,
  BurgerIcon,
  CheckIcon,
  CloseIcon,
  ControlIcon,
  ConsistentIcon,
  DashboardIcon,
  ExpandIcon,
  GrowthIcon,
  LandingIcon,
  LocalprofLogo,
  LocationsIcon,
  PointIcon,
  RequestIcon,
  ReviewIcon,
  SurveysIcon,
  UsersIcon,
  InstagramIcon,
  FacebookIcon,
};
