import { ContainerProps } from '@chakra-ui/react';
import { cacheExchange, createClient, fetchExchange } from '@urql/core';
import { FC, useMemo } from 'react';
import { Provider, ssrExchange } from 'urql';
import { config } from '@/shared/constants';

const GQLProvider: FC<ContainerProps> = ({ children }) => {
  const [client] = useMemo(() => {
    const ssr = ssrExchange({
      isClient: typeof window !== 'undefined',
    });
    const client = createClient({
      url: `${config.apiUrl}/graphql`,
      exchanges: [cacheExchange, ssr, fetchExchange],
      suspense: true,
    });

    return [client];
  }, []);

  return <Provider value={client}>{children}</Provider>;
};

export default GQLProvider;
