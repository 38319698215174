import { deviceType } from '@/shared/constants';
import { PageParams } from './parseSSGParams';

interface Params<T extends string | void = void> {
  params: PageParams<T>;
  locale?: string | undefined;
}

function getPaths<T>(
  params?: Record<keyof T, string>[],
): keyof T extends string ? Params<keyof T>[] : Params[] {
  const defaultParams = Object.values(deviceType).flatMap((device) => {
    return device;
  });

  const returnParams = params
    ? params.flatMap((param) =>
        defaultParams.map((ssgParams) => ({ params: { ssgParams, ...param } })),
      )
    : defaultParams.map((ssgParams) => ({ params: { ssgParams } }));

  return returnParams as ReturnType<typeof getPaths>;
}

export default getPaths;
