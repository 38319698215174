import { Icon, IconProps } from '@chakra-ui/react';

const ExpandIcon = (props: Omit<IconProps, 'children' | 'viewBox'>) => (
  <Icon
    fill='none'
    height='100%'
    stroke='currentColor'
    strokeLinecap='round'
    strokeLinejoin='round'
    strokeWidth='2'
    viewBox='0 0 24 24'
    width='100%'
    {...props}
  >
    <path
      d='M0 0h24v24H0z'
      fill='none'
      stroke='none'
    />
    <path d='M16 4l4 0l0 4' />
    <path d='M14 10l6 -6' />
    <path d='M8 20l-4 0l0 -4' />
    <path d='M4 20l6 -6' />
    <path d='M16 20l4 0l0 -4' />
    <path d='M14 14l6 6' />
    <path d='M8 4l-4 0l0 4' />
    <path d='M4 4l6 6' />
  </Icon>
);

export default ExpandIcon;
