import {
  Accordion,
  AccordionButton,
  AccordionIcon,
  AccordionItem,
  AccordionPanel,
  Link,
} from '@chakra-ui/react';
import { FC } from 'react';

interface ISelectItem {
  name: string;
  href: string;
}

interface ISelectAccordionProps {
  title: string;
  list: ISelectItem[];
}

const SelectAccordion: FC<ISelectAccordionProps> = ({ title, list }) => (
  <Accordion
    key={title}
    allowToggle
  >
    <AccordionItem border='none'>
      <AccordionButton
        bg='none !important'
        gap='sm'
        p='0'
      >
        {title}
        <AccordionIcon />
      </AccordionButton>
      {list.map(({ name, href }) => (
        <AccordionPanel
          key={name}
          pb='0'
          pt='sm'
        >
          <Link href={href}>{name}</Link>
        </AccordionPanel>
      ))}
    </AccordionItem>
  </Accordion>
);

export default SelectAccordion;
