import { Icon, IconProps } from '@chakra-ui/react';

const ControlIcon = (props: Omit<IconProps, 'children' | 'viewBox'>) => (
  <Icon
    fill='none'
    height='100%'
    stroke='currentColor'
    strokeLinecap='round'
    strokeLinejoin='round'
    strokeWidth='2'
    viewBox='0 0 24 24'
    width='100%'
    {...props}
  >
    <path
      d='M0 0h24v24H0z'
      fill='none'
      stroke='none'
    />
    <path d='M4 8h4v4h-4z' />
    <path d='M6 4l0 4' />
    <path d='M6 12l0 8' />
    <path d='M10 14h4v4h-4z' />
    <path d='M12 4l0 10' />
    <path d='M12 18l0 2' />
    <path d='M16 5h4v4h-4z' />
    <path d='M18 4l0 1' />
    <path d='M18 9l0 11' />
  </Icon>
);

export default ControlIcon;
