import { Link } from '@chakra-ui/next-js';
import { Button, Grid, Text } from '@chakra-ui/react';
import { brand, config, headerHeight } from '@/shared/constants';
import { DefaultContainer, LocalprofLogo } from '@/shared/ui';
import Menu from './Menu';

const Header = () => (
  <DefaultContainer
    alignItems='center'
    containerProps={{
      as: 'header',
      position: 'fixed',
      bg: 'white',
      boxShadow: 'lg',
      zIndex: 'sticky',
      top: 0,
    }}
    display='grid'
    gridTemplateAreas={{
      base: '"logo button menu"',
      md: '"logo menu button"',
    }}
    h={headerHeight}
    justifyContent='space-between'
    py='0'
  >
    <Grid
      _hover={{
        transform: 'scale(1.01)',
      }}
      alignItems='center'
      as={Link}
      gap='sm'
      gridArea='logo'
      href='/'
      templateColumns={{ base: 'auto', md: 'auto auto' }}
    >
      <LocalprofLogo boxSize={8} />
      <Text
        display={{ base: 'none', md: 'block' }}
        fontSize='xl'
        fontWeight='semibold'
      >
        {brand}
      </Text>
    </Grid>

    <Menu />

    <Button
      as={Link}
      gridArea='button'
      href={config.appUrl}
      variant={{ base: 'link', md: 'outline' }}
    >
      Login
    </Button>
  </DefaultContainer>
);

export default Header;
