type ColorKey = 50 | 100 | 200 | 300 | 400 | 500 | 600 | 700 | 800 | 900;

type ColorShades = Record<ColorKey, string>;

type CustomShades = [ColorKey | null, ColorKey | null, ColorKey | null, ColorKey | null];

const mapShades = (colors: ColorShades, customShades: CustomShades = [50, 300, 500, 700]) => {
  const defaultShades: CustomShades = [50, 300, 500, 700];

  const [lightKey, mediumKey, pureKey, darkKey] = customShades.map(
    (shade, index) => shade ?? defaultShades[index],
  );

  return {
    ...colors,
    light: colors[lightKey!],
    medium: colors[mediumKey!],
    pure: colors[pureKey!],
    dark: colors[darkKey!],
  };
};

export default mapShades;
