import dynamic from 'next/dynamic';
import { useGlobalState } from '@/shared/providers';

const MobileMenu = dynamic(() => import('./MobileMenu'));
const DesktopMenu = dynamic(() => import('./DesktopMenu'));

const Menu = () => {
  const { isMobile } = useGlobalState();

  if (isMobile) {
    return <MobileMenu />;
  }

  return <DesktopMenu />;
};

export default Menu;
