import {
  FormControl,
  FormErrorMessage,
  FormHelperText,
  FormLabel,
  Input,
  InputProps,
} from '@chakra-ui/react';
import { FC, useState } from 'react';
import { useController } from 'react-hook-form';
import { REQUIRED_FIELD_MSG } from '@/shared/constants';

export interface InputFieldProps extends InputProps {
  name: string;
  placeholder: string;
  isErrorSpace?: boolean;
  regExp?: RegExp | undefined;
  validationParameters?: object;
  label?: string;
  helperText?: string;
}

const InputField: FC<InputFieldProps> = ({
  name,
  placeholder,
  value,
  isErrorSpace = false,
  isRequired,
  validationParameters,
  label,
  helperText,
  onBlur,
  onChange,
  ...style
}) => {
  const [isShowHelperText, setShowHelperText] = useState(false);

  const {
    fieldState: { invalid, error },
    field: { onChange: onFieldChange, onBlur: onFieldBlur, ...restFiledProps },
  } = useController({
    name,
    rules: isRequired
      ? {
          required: REQUIRED_FIELD_MSG(label),
          ...validationParameters,
        }
      : validationParameters,
  });

  const handleChange: InputProps['onChange'] = (event) => {
    onFieldChange(event);
    onChange?.(event);
  };

  const handleBlur: InputProps['onBlur'] = (event) => {
    onFieldBlur();
    onBlur?.(event);
    setShowHelperText(false);
  };

  const showHelperText = isShowHelperText && helperText && !error?.message;
  const reserveSpaceForError = isErrorSpace && !showHelperText && !error?.message;

  return (
    <FormControl isInvalid={invalid}>
      {label && (
        <FormLabel
          color='primary.pure'
          mb='1'
        >
          {label}
        </FormLabel>
      )}
      <Input
        id={name}
        mb={reserveSpaceForError ? 'md' : 0}
        placeholder={placeholder}
        onBlur={handleBlur}
        onChange={handleChange}
        {...restFiledProps}
        {...style}
      />
      <FormErrorMessage>{error?.message}</FormErrorMessage>
      {showHelperText && <FormHelperText> {helperText}</FormHelperText>}
    </FormControl>
  );
};

export default InputField;
