import {
  Box,
  FormControl,
  FormErrorMessage,
  FormLabel,
  NumberInput,
  NumberInputField,
  NumberInputProps,
} from '@chakra-ui/react';
import { FC } from 'react';
import { useController } from 'react-hook-form';
import { INVALID_PHONE_MSG, phoneRegExp, phoneRegExpWithSpaces } from '@/shared/constants';
import { phoneFormat } from '@/shared/helpers';

interface PhoneFieldProps extends NumberInputProps {
  validationParameters?: object;
}

const PhoneField: FC<PhoneFieldProps> = ({
  name = 'phone',
  onChange,
  onBlur,
  validationParameters,
  ...rest
}) => {
  const {
    fieldState,
    field: { onChange: onFieldChange, onBlur: onFieldBlur, value },
  } = useController({
    name,
    rules: {
      pattern: {
        value: new RegExp(phoneRegExp),
        message: INVALID_PHONE_MSG,
      },
      ...validationParameters,
    },
  });
  const { invalid, error } = fieldState;

  const handleChange: NumberInputProps['onChange'] = (stringValue, numberValue) => {
    onFieldChange(stringValue);
    onChange?.(stringValue, numberValue);
  };

  const handleBlur: NumberInputProps['onBlur'] = (e) => {
    onFieldBlur();
    onBlur?.(e);
  };

  return (
    <FormControl isInvalid={invalid}>
      <FormLabel
        color='primary.pure'
        mb='1'
      >
        Phone
      </FormLabel>
      <NumberInput
        inputMode='tel'
        pattern={phoneRegExpWithSpaces}
        value={value && phoneFormat(value.toString())}
        onBlur={handleBlur}
        onChange={handleChange}
        {...rest}
      >
        <NumberInputField
          id='phone'
          placeholder='Phone'
        />
      </NumberInput>
      <Box
        minH={4}
        my='1.5'
      >
        <FormErrorMessage>{error?.message}</FormErrorMessage>
      </Box>
    </FormControl>
  );
};

export default PhoneField;
