type Procedure = (...args: any[]) => any;

const throttle = <T extends Procedure>(
  callback: T,
  delay = 1000,
): ((...args: Parameters<T>) => Promise<ReturnType<T> | void>) => {
  let timerId: ReturnType<typeof setTimeout> | null = null;

  return (...args: Parameters<T>): Promise<ReturnType<T> | void> => {
    return new Promise<ReturnType<T> | void>((resolve, reject) => {
      if (timerId) {
        clearTimeout(timerId);
      }
      timerId = setTimeout(() => {
        try {
          const result = callback(...args);
          resolve(result);
        } catch (error) {
          reject(error);
        }
      }, delay);
    });
  };
};

export default throttle;
