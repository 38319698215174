import { Link } from '@chakra-ui/next-js';
import { Flex, IconButton, IconProps } from '@chakra-ui/react';
import { FC } from 'react';
import { socialMediaLinks } from '../lib/socialMediaLinks';

interface SocialMediaIconProps {
  href: string;
  Icon: FC<IconProps>;
  ariaLabel: string;
}

const SocialMediaIconLink = ({ href, Icon, ariaLabel }: SocialMediaIconProps) => (
  <IconButton
    alignContent='center'
    aria-label={ariaLabel}
    as={Link}
    color='text.light'
    href={href}
    icon={<Icon boxSize={8} />}
    size='sm'
    target='_blank'
    variant='unstyled'
  />
);

const SocialMediaLinks = () => (
  <Flex justifyContent='center'>
    {socialMediaLinks.map((data) => (
      <SocialMediaIconLink
        key={data.ariaLabel}
        {...data}
      />
    ))}
  </Flex>
);

export default SocialMediaLinks;
