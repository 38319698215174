import {
  FormControl,
  FormErrorMessage,
  FormHelperText,
  FormLabel,
  Textarea,
  TextareaProps,
} from '@chakra-ui/react';
import { Control, FieldPath, FieldValues, RegisterOptions, useController } from 'react-hook-form';
import { REQUIRED_FIELD_MSG } from '@/shared/constants';

type TextareaFieldProps<
  TFieldValues extends FieldValues = FieldValues,
  TName extends FieldPath<TFieldValues> = FieldPath<TFieldValues>,
> = TextareaProps & {
  control?: Control<TFieldValues>;
  name: TName;
  validationParameters?: Omit<
    RegisterOptions<TFieldValues, TName>,
    'disabled' | 'setValueAs' | 'valueAsNumber' | 'valueAsDate'
  >;
  label?: string;
  helperText?: string;
  isErrorSpace?: boolean;
};

const TextareaField = <
  TFieldValues extends FieldValues = FieldValues,
  TName extends FieldPath<TFieldValues> = FieldPath<TFieldValues>,
>({
  name,
  control,
  validationParameters = {},
  helperText,
  isErrorSpace = false,
  isRequired,
  label,
  onChange,
  onBlur,
  ...rest
}: TextareaFieldProps<TFieldValues, TName>) => {
  const {
    fieldState: { invalid, error },
    field: { onChange: onFieldChange, onBlur: onFieldBlur, ...restFiledProps },
  } = useController({
    name,
    control,
    rules: isRequired
      ? {
          required: REQUIRED_FIELD_MSG(label),
          ...validationParameters,
        }
      : validationParameters,
  });

  const handleChange: TextareaProps['onChange'] = (event) => {
    onFieldChange(event);
    onChange?.(event);
  };

  const handleBlur: TextareaProps['onBlur'] = (event) => {
    onFieldBlur();
    onBlur?.(event);
  };

  const showHelperText = helperText && !invalid;
  const reserveSpaceForError = isErrorSpace && !showHelperText && !error?.message;

  return (
    <FormControl
      isInvalid={invalid}
      isRequired={isRequired}
    >
      {label && (
        <FormLabel
          color='primary.pure'
          mb='1'
        >
          {label}
        </FormLabel>
      )}
      <Textarea
        mb={reserveSpaceForError ? 'lg' : 0}
        onBlur={handleBlur}
        onChange={handleChange}
        {...rest}
        {...restFiledProps}
      />
      <FormErrorMessage mt={0}>{error?.message}</FormErrorMessage>
      {showHelperText && <FormHelperText mt={0}>{helperText}</FormHelperText>}
    </FormControl>
  );
};

export default TextareaField;
