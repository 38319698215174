import mapShades from './mapShades';

const primary = {
  50: '#FFF5F5',
  100: '#FED7D7',
  200: '#FEB2B2',
  300: '#FC8181',
  400: '#F56565',
  500: '#d44c57',
  600: '#C53030',
  700: '#9B2C2C',
  800: '#822727',
  900: '#63171B',
};

const secondary = {
  50: '#F7FAFC',
  100: '#EDF2F7',
  200: '#E2E8F0',
  300: '#c3cbd3',
  400: '#abb5c1',
  500: '#828995',
  600: '#535a66',
  700: '#363c47',
  800: '#252527',
  900: '#1e1f25',
};

const gray = {
  50: '#F7FAFC',
  100: '#EDF2F7',
  200: '#E2E8F0',
  300: '#CBD5E0',
  400: '#A0AEC0',
  500: '#718096',
  600: '#4A5568',
  700: '#2D3748',
  800: '#1A202C',
  900: '#171923',
};

const blackAlpha = {
  50: '#00000004',
  100: '#00000006',
  200: '#00000008',
  300: '#00000016',
  400: '#00000024',
  500: '#00000036',
  600: '#00000048',
  700: '#00000064',
  800: '#00000080',
  900: '#00000092',
};

const whiteAlpha = {
  50: '#ffffff04',
  100: '#ffffff06',
  200: '#ffffff08',
  300: '#ffffff16',
  400: '#ffffff24',
  500: '#ffffff36',
  600: '#ffffff48',
  700: '#ffffff64',
  800: '#ffffff80',
  900: '#ffffff92',
};

const success = {
  50: '#8ECAAA',
  100: '#8ECAAA',
  200: '#77BF98',
  300: '#5AB283',
  400: '#38A169',
  500: '#33925F',
  600: '#205B3B',
  700: '#1A4B31',
  800: '#18442D',
  900: '#163E29',
};

const error = {
  50: '#FED7D7',
  100: '#fbbaba',
  200: '#f28e8e',
  300: '#eb6161',
  400: '#e43535',
  500: '#C11A1A',
  600: '#9e1314',
  700: '#710c0e',
  800: '#460506',
  900: '#1e0000',
};

const colors = {
  black: '#292C2E',
  primary: mapShades(primary),
  secondary: mapShades(secondary),
  gray: mapShades(gray),
  blackAlpha: mapShades(blackAlpha),
  whiteAlpha: mapShades(whiteAlpha),
  success: mapShades(success),
  error: mapShades(error),
  text: {
    light: gray[300],
    medium: '#3a4352',
    pure: gray[700],
    dark: gray[900],
  },
  bg: {
    pure: '#ffffff',
    light: '#F5F7FE',
    medium: '#EBEFFE',
    dark: '#2A3F68',
  },
  border: {
    light: gray[100],
    medium: gray[200],
    pure: gray[300],
    dark: gray[500],
    focus: gray[500],
  },
  icon: {
    light: gray[200],
    medium: gray[300],
    pure: gray[500],
    dark: gray[600],
  },
  starRating: {
    rating: '#f0c400',
    background: '#b1c8dd',
  },
  gradient: {
    pure: 'linear-gradient(69.3deg, #D44B56 16.57%, #EE6E78 108.7%)',
    light: 'linear-gradient(125deg, #FFFFFF -20%, #F4DCDC 20%, #ECF0FF 80%)',
    dark: 'linear-gradient(69.3deg, #D44B56a8 16.57%, #EE6E7899 108.7%)',
  },
};

export default colors;
